@import url('https://fonts.cdnfonts.com/css/harmonyos-sans');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Jura:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'HarmonyOS Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(88, 86, 86);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #121212;
  border-left: #27272a solid 1px;
}

::-webkit-scrollbar-thumb {
  background-color: #768d5c;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #97b764;
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

.inria-serif-regular {
  font-family: "Inria Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.custom-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.caveat-text {
  font-family: "Caveat", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.oswald-text {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.mask::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(./assets/img/background-1.jpg) center / cover no-repeat;
  z-index: 0;
}

.mask::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.35);
  z-index: 1;
}

.mask-services::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(./assets/img/main-bg2-scaled.jpg) center / cover no-repeat;
  z-index: 0;
}

.mask-services::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.35);
  z-index: 1;
}